import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Links as SiteMetadataLinks } from "~types/SiteMetadata";
import LinkCollectionButton from "./LinkCollectionButton";

interface Props {
	hideGenralLink?: boolean;
}

const ServiceLinks: React.FC<Props> = ({ hideGenralLink }) => {
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					services {
						text
						to
					}
				}
			}
		}
	`);

	const { services }: { services: SiteMetadataLinks[] } = data.site.siteMetadata;

	return (
		<nav className="grid gap-6 md:grid-cols-2">
			{services.map((link) => {
				if (link.to === "/leistungen/" && hideGenralLink) {
					return null;
				}
				return <LinkCollectionButton to={link.to} text={link.text} key={link.text} />;
			})}
		</nav>
	);
};

export default ServiceLinks;
