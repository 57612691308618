import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Links as SiteMetadataLinks } from "~types/SiteMetadata";
import LinkCollectionButton from "./LinkCollectionButton";

const IndustrySectorExpertLinks: React.FC = () => {
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					industrySectors {
						text
						to
					}
				}
			}
		}
	`);

	const { industrySectors }: { industrySectors: SiteMetadataLinks[] } = data.site.siteMetadata;

	return (
		<nav className="grid gap-6 md:grid-cols-2">
			{industrySectors.map((link) => (
				<LinkCollectionButton to={link.to} text={link.text} key={link.text} />
			))}
		</nav>
	);
};

export default IndustrySectorExpertLinks;
