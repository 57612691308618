import { graphql, Link } from "gatsby";
import React from "react";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import Grid from "~components/common/Grid";
import ServiceExpertsLinks from "~components/linkCollections/ServiceExpertsLinks";
import ServiceIndustrySectorsLinks from "~components/linkCollections/ServiceIndustrySectorsLinks";
import ServiceLinks from "~components/linkCollections/ServiceLinks";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";
import H2 from "~components/typography/H2";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";

export default function Page({ data, location }) {
	return (
		<Layout
			location={location}
			title="Werbeagentur Leistungen | Unsere indiviudellen Lösungen"
			desc="Konzeptionierung und Realisierung von Profis. Wir bauen nicht nur Websites, sondern entwickeln Dein Business."
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Breadcrumb pages={[{ name: "Leistungen", to: "/leistungen/" }]} />
			<Container noPadding className="py-6">
				<Divider className="mb-4" />
				<NumberedSection text="Konzept und Entwicklung" number="01" className="mb-5" />
				<H1 display className="mb-5 font-display">
					Werbeagentur Leistungen: Unsere indiviudellen Lösungen für Dich
				</H1>
				<Grid cols={3}>
					<div>
						<P>
							Als Werbeagentur mit Spezialisierung auf Digitalisierung und online Marketing, bieten wir Dir eine Auswahl
							an indiviudellen Leistungen und Lösungen an, die Dein Business voranbringen werden. Suchst Du eine
							bestimmte Leistung, die hier nicht aufgelistet ist? Du kannst uns immer eine unverbindliche{" "}
							<Link to="/beauftragen/" className="underline">
								Anfrage stellen
							</Link>
							, solltest Du ein speziellere Lösung suchen.
						</P>
					</div>
					<div className="md:col-span-2">
						<ServiceLinks hideGenralLink />
					</div>
				</Grid>
			</Container>
			<Container>
				<Divider className="mb-4" />
				<NumberedSection text="Von Experten für Experten" number="02" className="mb-5" />
				<H2 display className="mb-5 font-display">
					Expertisen-Seiten
				</H2>
				<Grid cols={3}>
					<div>
						<P>
							Wir sind auf bestimmte Technologien spezialisiert. Suchst Du einen Experten in diesen Bereichen, stehen
							wir Dir gerne zur Verfügung.
						</P>
					</div>
					<div className="md:col-span-2">
						<ServiceExpertsLinks />
					</div>
				</Grid>
			</Container>
			<Container>
				<Divider className="mb-4" />
				<NumberedSection text="Von Experten für Dich" number="03" className="mb-5" />
				<H2 display className="mb-5 font-display">
					Branchenseiten
				</H2>
				<Grid cols={3}>
					<div>
						<P>
							Neben unser Auswahl an Leistungen, bieten wir auch Websites an, die wir für bestimmte Anwendungsbereiche
							optimiert haben. So findest Du hier eine Auswahl unseres Angebots.
						</P>
					</div>
					<div className="md:col-span-2">
						<ServiceIndustrySectorsLinks />
					</div>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/leistungen/leistungen_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
				contact {
					street
					plz
					phone
					mail
					city
				}
			}
		}
	}
`;
