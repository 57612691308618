import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Links as SiteMetadataLinks } from "~types/SiteMetadata";
import LinkCollectionButton from "./LinkCollectionButton";

const ServiceExpertLinks: React.FC = () => {
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					servicesExperts {
						text
						to
					}
				}
			}
		}
	`);

	const { servicesExperts }: { servicesExperts: SiteMetadataLinks[] } = data.site.siteMetadata;

	return (
		<nav className="grid gap-6 md:grid-cols-2">
			{servicesExperts.map((link) => (
				<LinkCollectionButton to={link.to} text={link.text} key={link.text} />
			))}
		</nav>
	);
};

export default ServiceExpertLinks;
