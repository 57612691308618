import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import React from "react";

interface Props {
	to: string;
	text: string;
}

const LinkCollectionButton: React.FC<Props> = ({ to, text }) => {
	return (
		<>
			<Link
				to={to}
				className="flex items-center justify-between p-3 text-2xl transition-opacity duration-300 ease-out border bg-pattern font-display hover:opacity-50"
			>
				{text} <ChevronRightIcon className="w-6 h-6" />
			</Link>
		</>
	);
};

export default LinkCollectionButton;
